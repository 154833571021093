// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.home_hline__BGDZj{
    border: 1px solid black !important;
    margin-top: 20px !important;
    width: 70%;
    margin: auto;
    z-index: 10 !important;
    background-color: #ccc;

}


@media screen and (max-width:965px) {

    .home_hline__BGDZj{
        width: 90% !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/home.module.css"],"names":[],"mappings":";;AAEA;IACI,kCAAkC;IAClC,2BAA2B;IAC3B,UAAU;IACV,YAAY;IACZ,sBAAsB;IACtB,sBAAsB;;AAE1B;;;AAGA;;IAEI;QACI,qBAAqB;IACzB;AACJ","sourcesContent":["\n\n.hline{\n    border: 1px solid black !important;\n    margin-top: 20px !important;\n    width: 70%;\n    margin: auto;\n    z-index: 10 !important;\n    background-color: #ccc;\n\n}\n\n\n@media screen and (max-width:965px) {\n\n    .hline{\n        width: 90% !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hline": `home_hline__BGDZj`
};
export default ___CSS_LOADER_EXPORT___;
