import { useCookies } from "react-cookie"


export function Cookies(){

    const[cookies, setCookies, removeCookies] = useCookies()

    return {
        "role": cookies["role"],
        "token" : cookies["token"]
    }
}