import { useEffect, useState } from "react";
import Banneri from "../../components/Banner/Banneri";
import Card from "../../components/Card/Card";
import home from "./home.module.css";
import { prod, getLength } from "./Produkte";
import Paggination from "../../components/Paggination/Pagination";
import axios from "axios";
import LoadingIndicator from 'react-loading-indicator';
import { handleResizeWindow } from "../../Functions/HandleResizeWindow";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function Home() {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(9);
    const [products, setProducts] = useState([]);
    const [totalPage, setTotalPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const { t, i18n } = useTranslation('global'); // Specify the namespace if needed
    const baseApiUrl = process.env.REACT_APP_BASE_API_URL;


    function handleResize() {
        const newLimit = handleResizeWindow();
        setLimit(newLimit);
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        function getAllProducts() {
            setLoading(true);
            axios.get(`${baseApiUrl}/products`)
                .then(response => {
                    setProducts(response.data[0]);
                    setTotalPage(Math.ceil(getLength(response.data[0]) / limit));
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error fetching products:", error);
                    setLoading(false);
                });
        }

        getAllProducts();
    }, [page, limit]);

    function handlePageChange(value) {
        switch (value) {
            case "R":
                window.scrollTo({
                    top: 570,
                });
                if (page < totalPage) {
                    setPage(page + 1);
                  
                }
                break;
            case "L":
                window.scrollTo({
                    top: 570,
                });
                if (page > 1) {
                    setPage(page - 1);
                }
                break;
            default:
                window.scrollTo({
                    top: 570,
                });
                setPage(value);
        }
    }

    return (
        <div style={{ zIndex: "-1" }}>
            <Banneri />
            <div style={{ marginTop: "50px" }}>
                <h1>{t("home.produktet")}</h1>
                <hr className={home.hline} />
            </div>
            <div>
                {loading ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        <Card produktet={prod(page, limit, products)} />
                        <Paggination totalPage={totalPage} page={page} limit={limit} siblings={1} onPageChange={handlePageChange} />
                    </>
                )}
            </div>
        </div>
    );
}
