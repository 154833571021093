import productsOnDashboard from "../Data/ProductsOnDashboard";

export function UpdateDashboard(productId, quantity) {
    for (let i = 0; i < productsOnDashboard.length; i++) {
        if (productsOnDashboard[i].id == productId) {
            productsOnDashboard[i].quantity = quantity;
            console.log("Updated product " + productsOnDashboard[i].id);
            break;
        }
    }
}