

var produkte = [
    {   
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { 
    "img": "https://dam.bluenile.com/images/public/16588/Lab_Grown_Diamonds.jpeg",
    "price": 123,
    "discount": "30%",
    "name": "Zingjire"},
   
    
    
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { 
    "img": "https://dam.bluenile.com/images/public/16588/Lab_Grown_Diamonds.jpeg",
    "price": 123,
    "discount": "30%",
    "name": "Zingjire"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { 
    "img": "https://dam.bluenile.com/images/public/16588/Lab_Grown_Diamonds.jpeg",
    "price": 123,
    "discount": "30%",
    "name": "Zingjire"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { 
    "img": "https://dam.bluenile.com/images/public/16588/Lab_Grown_Diamonds.jpeg",
    "price": 123,
    "discount": "30%",
    "name": "Zingjire"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { 
    "img": "https://dam.bluenile.com/images/public/16588/Lab_Grown_Diamonds.jpeg",
    "price": 123,
    "discount": "30%",
    "name": "Zingjire"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { 
    "img": "https://dam.bluenile.com/images/public/16588/Lab_Grown_Diamonds.jpeg",
    "price": 123,
    "discount": "30%",
    "name": "Zingjire"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { 
    "img": "https://dam.bluenile.com/images/public/16588/Lab_Grown_Diamonds.jpeg",
    "price": 123,
    "discount": "30%",
    "name": "Zingjire"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { 
    "img": "https://dam.bluenile.com/images/public/16588/Lab_Grown_Diamonds.jpeg",
    "price": 123,
    "discount": "30%",
    "name": "Zingjire"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { 
    "img": "https://dam.bluenile.com/images/public/16588/Lab_Grown_Diamonds.jpeg",
    "price": 123,
    "discount": "30%",
    "name": "Zingjire"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { 
    "img": "https://dam.bluenile.com/images/public/16588/Lab_Grown_Diamonds.jpeg",
    "price": 123,
    "discount": "30%",
    "name": "Zingjire"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { 
    "img": "https://dam.bluenile.com/images/public/16588/Lab_Grown_Diamonds.jpeg",
    "price": 123,
    "discount": "30%",
    "name": "Zingjire"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { 
    "img": "https://dam.bluenile.com/images/public/16588/Lab_Grown_Diamonds.jpeg",
    "price": 123,
    "discount": "30%",
    "name": "Zingjire"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { 
    "img": "https://dam.bluenile.com/images/public/16588/Lab_Grown_Diamonds.jpeg",
    "price": 123,
    "discount": "30%",
    "name": "Zingjire"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { 
    "img": "https://dam.bluenile.com/images/public/16588/Lab_Grown_Diamonds.jpeg",
    "price": 123,
    "discount": "30%",
    "name": "Zingjire"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
    "price": 234,
    "discount": "40%",
    "name": "Ore"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    { 
    "img": "https://dam.bluenile.com/images/public/16588/Lab_Grown_Diamonds.jpeg",
    "price": 123,
    "discount": "30%",
    "name": "Zingjire"},
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    {
        "img":"https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price":"111",
        "discount":"70%",
        "name": "Unaze"
    },
    


]
   
export default produkte;