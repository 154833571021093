import React, { useEffect, useState, useCallback } from "react";
import style from "./Dashboard.module.css";
import { useCookies } from "react-cookie";
import productsOnDashboard from "../../Data/ProductsOnDashboard";
import axios from "axios";
import { UpdateDashboard } from "../../Functions/UpdateDashboard";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import 'bootstrap/dist/css/bootstrap.min.css';

import personalization from "../../Data/Personalization";


export default function Dashboard({ updateProductToDashboard }) {
    const [products, setProducts] = useState([]);
    const [quantity, setQuantity] = useState({});
    const [country, setCountry] = useState("Kosove")
    const kosovaCities = [
        "Deçan",
        "Dragash",
        "Drenas",
        "Ferizaj",
        "Fushë Kosova",
        "Gjakova",
        "Gjilan",
        "Istog ",
        "Kaçaniku",
        "Kamenicë",
        "Klinë",
        "Lipjan",
        "Malisheva",
        "Mitrovica",
        "Obiliq ",
        "Peja",
        "Podujeva ",
        "Prishtina",
        "Prizreni",
        "Rahoveci",
        "Skenderaj",
        "Suharekë",
        "Shtërpca",
        "Shtime",
        "Viti",
        "Vushtrria"
    ];

    const albanianCities = [
        "Ballshi", "Bajram Curri", "Berati", "Bilishti", "Bulqiza", "Burreli", "Cërriku",
        "Delvina", "Divjaka", "Durrësi", "Elbasani", "Erseka", "Fieri", "Fushë-Arrëza",
        "Fushë-Kruja", "Gjirokastra", "Gramshi", "Himara", "Kamëza", "Kavaja", "Kopliku",
        "Korça", "Kruja", "Kruma", "Kukësi", "Kuçova", "Këlcyra", "Këraba", "Laçi",
        "Leskoviku", "Libohova", "Librazhdi", "Lushnja", "Maliqi", "Mamurrasi", "Memaliaj",
        "Miloti", "Orikumi", "Patosi", "Peqini", "Peshkopia", "Pogradeci", "Poliçani",
        "Prrenjasi", "Puka", "Rrogozhina", "Rubiku", "Rrësheni", "Roskoveci", "Saranda",
        "Selenica", "Shijaku", "Shkodra", "Shëngjini", "Tepelena", "Tirana", "Ura Vajgurore",
        "Vlora", "Vora"
    ];

    const [moneyPosta, setMoneyPosta] = useState(0)
    const [cookies, setCookies] = useCookies();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        address: '',
        phoneNumber: '',
        city: ''
    });
    const [showFormModal, setShowFormModal] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertType, setAlertType] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation('global');
    const [loading, setLoading] = useState(false);
    const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

    let totali = (productsOnDashboard.length == 0 ? 0 : (country == "Kosove") ? 0 : 4);

    const loadToDashboard = useCallback(() => {
        if (productsOnDashboard.length !== 0) {
            let idOfProducts = productsOnDashboard.map(prod => prod.id);
            axios.get(`${baseApiUrl}/specificProduct/${idOfProducts.toString()}`).then(rez => {
                var allProducts = rez.data;
                let indexOfElementToRemove = allProducts.findIndex(prod => prod.CategoryName === "Personalizime");
                if (indexOfElementToRemove !== -1) {
                    allProducts.splice(indexOfElementToRemove, 1);
                }
                personalization.forEach(prod => {
                    allProducts.push(prod);
                });
                setProducts(allProducts);
            });
            setCookies("products", productsOnDashboard);
        } else {
            setProducts([]);
        }
    }, [setCookies]);

    useEffect(() => {
        loadToDashboard();
        changeTotal();
    }, [loadToDashboard]);

    function handleRemoveButton(e) {
        let id = parseInt(e.target.id);
        let indexOfElementToRemove = productsOnDashboard.findIndex(prod => prod.id === id);
        if (indexOfElementToRemove !== -1) {
            productsOnDashboard.splice(indexOfElementToRemove, 1);
            loadToDashboard();
            updateProductToDashboard(productsOnDashboard.length);
        }
    }

    function handleRemovePersonalizedButton(e) {
        let id = parseInt(e.target.id);
        let indexOfElementToRemove = personalization.findIndex(prod => prod.PersonalizedId === id);
        if (indexOfElementToRemove !== -1) {
            personalization.splice(indexOfElementToRemove, 1);
        }

        let indexOfElementToRemoveOnDashboard = productsOnDashboard.findIndex(prod => prod.PersonalizedId === id);
        if (indexOfElementToRemoveOnDashboard !== -1) {
            productsOnDashboard.splice(indexOfElementToRemoveOnDashboard, 1);
            loadToDashboard();
            updateProductToDashboard(productsOnDashboard.length);
        }
    }

    function handleQuantityChange(price, id, event) {
        const newQuantity = { ...quantity, [id]: parseInt(event.target.value) };
        UpdateDashboard(id, event.target.value);
        setQuantity(newQuantity);
    }

    function getQuantity(id) {
        for (let i = 0; i < productsOnDashboard.length; i++) {
            if (productsOnDashboard[i].id === id) {
                return productsOnDashboard[i].quantity;
            }
        }
    }

    function calculateSubtotal(price, id) {
        let calculateSubtotal = price * getQuantity(id);
        totali = calculateSubtotal + totali;
        return calculateSubtotal.toFixed(2);
    }

    function calculateSubTotalPersonalized(price) {
        totali += price;
        return price.toFixed(2);
    }

    function changeTotal(price, id) { }

    function handleByDashboard() {
        if (cookies["token"]) {
            setShowFormModal(true);
        } else {
            setShowFormModal(false);
        }
    }

    function handleRegister() {
        navigate("/login");
    }

    function handleInputChange(event) {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    }
    async function handleSubmit(event) {
        event.preventDefault();
        setLoading(true); // Set loading to true when the request starts
        const userId = cookies["userId"];
        const email = cookies["email"];
        const token = cookies["token"];

        // Create an array of promises for buying products
        const buyProductPromises = productsOnDashboard.map(prod => {
            const payload = {
                userId: userId,
                productId: prod.id,
                quantity: prod.quantity,
                firstName: formData.firstName,
                lastName: formData.lastName,
                phoneNumber: formData.phoneNumber,
                email: email,
                address: formData.address,
                city: formData.city
            };

            return axios.post(`${baseApiUrl}/buyProduct`, payload, {
                headers: {
                    'Authorization': token
                },
                timeout: 10000 // Set timeout to 10 seconds
            }).catch(error => {
                console.error(`Error buying product ${prod.id}:`, error);
                return { status: 500 }; // Return a failed response status
            });
        });
        // Create an array of promises for sending emails
        const emailPromises = productsOnDashboard.map(prod => {
            let emailPayload = {
                produkti: prod.id,
                sasia: prod.quantity,
                emri: formData.firstName,
                mbiemri: formData.lastName,
                adresa: formData.address,
                numri: formData.phoneNumber,
                qyteti: formData.city,
                phoneNumber: formData.phoneNumber,
                email: cookies["email"]
            };

            if (prod.PersonalizedId) {
                const personalizedItem = personalization.find(p => p.PersonalizedId === prod.PersonalizedId);
                if (personalizedItem) {
                    emailPayload = {
                        ...emailPayload,
                        font: personalizedItem.FontName,
                        text: personalizedItem.Name,
                        color: personalizedItem.Color == 'c0c0c0' ? 'Argjendi' : 'Ari'
                    };
                }
            }

            return axios.post(`${baseApiUrl}/sendEmail`, emailPayload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                timeout: 10000 // Set timeout to 10 seconds
            }).catch(error => {
                console.error(`Error sending email for product ${prod.id}:`, error);
                return { status: 500 }; // Return a failed response status
            });
        });

        try {
            // Execute all requests concurrently
            const buyResponses = await Promise.all(buyProductPromises);
            const emailResponses = await Promise.all(emailPromises);

            // Check if all email requests were successful
            const emailSuccessful = emailResponses.every(response => response.status === 200);
            if (emailSuccessful) {
                setAlertMessage("Blerja u krye me sukses!");
                setAlertType("success");
                productsOnDashboard.length = 0;
                personalization.length = 0;
                setProducts([]);
                setCookies("products", []);
                updateProductToDashboard(0);
                navigate("/purchaseHistoryUser");  // Redirect to purchase history page
            } else {
                setAlertMessage("Produkti nuk u ble me sukses");
                setAlertType("warning");
            }
        } catch (error) {
            console.error('Error:', error);
            setAlertMessage("Një gabim ndodhi gjatë përpunimit të kërkesës");
            setAlertType("danger");
        } finally {
            setLoading(false); // Set loading to false when the request finishes
        }
    }

    function handleCountryChange(e) {
        var value = e.target.value
        setCountry(value)
        if (value == "Kosove") {
            setMoneyPosta(0)
        }
        else {
            setMoneyPosta(4)
        }

        if (value == "Kosove" && productsOnDashboard.length != 0) {
            loadToDashboard()
        }
        else if (value == "Shqiperi" && productsOnDashboard.length != 0) {

            loadToDashboard()
        }
    }

    return (
        <div className={`${style.main} container-fluid`}>
            <h1 className={`${style.dashboard}`}>{t("shporta.shporta")}</h1>

            {/* Alert Message */}
            {alertMessage && (
                <div className={`alert alert-${alertType} mt-3`} role="alert">
                    {alertMessage}
                </div>
            )}

            {/* Loader */}
            {loading && (
                <div className="loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}

            {!loading && (
                <>
                    <table className={`table ${style.table}`}>
                        <thead className={`${style.thead}`}>
                            <tr className={`${style.firstRow}`}>
                                <td style={{ width: "50%" }}><span id={style.productName}>{t("shporta.produkti")}</span></td>
                                <td style={{ width: "10%" }}>{t("shporta.cmimi")}</td>
                                <td style={{ width: "20%", alignContent: "center" }}>{t("shporta.sasia")}</td>
                                <td style={{ width: "10%", textAlign: "end" }}>{t("shporta.nentotali")}</td>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map(prod => (
                                <tr key={prod.ProductID} className={`${style.productDescription}`}>
                                    <td className={`${style.productCol}`} >
                                        {prod.CategoryName === "Personalizime" ? (
                                            <span><i id={prod.PersonalizedId} className="bi bi-x-circle" style={{ cursor: "pointer" }} onClick={handleRemovePersonalizedButton}></i></span>
                                        ) : (
                                            <span><i id={prod.ProductID} className="bi bi-x-circle" style={{ cursor: "pointer" }} onClick={handleRemoveButton}></i></span>
                                        )}
                                        {prod.CategoryName === "Personalizime" ? (
                                            <span data-title="Product" style={{ display: "flex", justifyContent: "space-between" }}><div style={{ width: "100px", height: "80px", background: "linear-gradient(45deg, #e6e7e8, white, #e6e7e8)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <span style={{ fontFamily: `${prod?.FontName}`, fontWeight: "900", fontSize: "25px", letterSpacing: "-0.09em", color: `${prod?.Color}` }}>{prod?.Name}</span>
                                            </div></span>
                                        ) : (
                                            <span data-title="Product"><img src={prod.ProductImages?.split(",")[0]} style={{ width: "100px", height: "100px" }} alt={prod.ProductName}></img></span>
                                        )}
                                        <span className={`${style.productNameLabel}`}>{prod.ProductName}</span>
                                    </td>
                                    <td data-title="Cmimi" style={{ alignContent: "center" }} id={`${style.cmimi}`} ><span style={{}}>{prod.Price.toFixed(2)}</span></td>
                                    {prod.CategoryName === "Personalizime" ? (
                                        <td data-title="Sasia" className={`${style.sasia}`} style={{ alignContent: "center" }}>1</td>
                                    ) : (
                                        <td data-title="Sasia" className={`${style.sasia}`} style={{ alignContent: "center" }}>
                                            <input
                                                className={`${style.input}`}
                                                type="number"
                                                min="1"
                                                step="1"
                                                max={prod.StockQuantity}
                                                defaultValue={getQuantity(prod.ProductID)}
                                                onChange={(event) => handleQuantityChange(prod.Price, prod.ProductID, event)}
                                            />
                                        </td>
                                    )}
                                    {prod.CategoryName === "Personalizime" ? (
                                        <td data-title="Nentotali" className={`${style.nentotali}`} style={{ textAlign: "end", alignContent: "center" }}>{calculateSubTotalPersonalized(prod.Price)}</td>
                                    ) : (
                                        <td data-title="Nentotali" className={`${style.nentotali}`} style={{ textAlign: "end", alignContent: "center" }}>{calculateSubtotal(prod.Price, prod.ProductID)}</td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <table className={'table'} style={{ display: (productsOnDashboard.length == 0 ? "none" : "") }}>
                        <tbody >
                            <tr >
                                <td style={{ width: "50%", left: "30%", borderTop: "none", alignContent: "center" }}><span id={style.dergesa}>{t("shporta.dergesa")}</span></td>
                                <td style={{ width: "10%", borderTop: "none", alignContent: "center" }} className={`${style.country}`}>
                                    <span style={{ whiteSpace: "nowrap" }}> <input type="radio" name="country" defaultChecked value="Kosove" onChange={handleCountryChange}></input><label style={{ marginRight: "10px" }}>Kosove</label></span>
                                    <span style={{ whiteSpace: "nowrap" }}> <input type="radio" name="country" value="Shqiperi" onChange={handleCountryChange}></input><label>Shqiperi</label></span>
                                </td>
                                <td style={{ textAlign: "end", width: "20%", borderTop: "none", alignContent: "center" }}>
                                    <span style={{ whiteSpace: "nowrap", fontWeight: "600", fontSize: "18px" }}>Posta: </span>
                                </td>
                                <td style={{ textAlign: "end", width: "10%", borderTop: "none", alignContent: "center" }}>
                                    <span style={{ whiteSpace: "nowrap" }}>{moneyPosta.toFixed(2)} <sapn style={{ fontWeight: "400", fontWeight: "bold" }}></sapn></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className={`${style.secondTable}`}>
                        <table className={`table`} style={{ marginTop: "0" }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: "50%", border: "none" }}>
                                    </td>
                                    <td style={{ width: "10%", border: "none" }}></td>
                                    <td className={`${style.totalLabel}`} style={{ textAlign: "end", width: "20%", borderBottom: "none", borderTop: "3px solid black" }}>{t("shporta.totali")}:</td>
                                    <td className={`${style.total}`} style={{ textAlign: "end", width: "10%", whiteSpace: "nowrap", borderBottom: "none", borderTop: "3px solid black" }}>{totali.toFixed(2)} <span style={{ fontWeight: "bold" }}>Euro</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{ marginTop: "40px", textAlign: "end" }} className={`${style.buttonDiv}`}>
                        <button className={`${style.button}`} data-bs-target="#delete" data-bs-toggle="modal" disabled={productsOnDashboard.length == 0 ? true : false} onClick={handleByDashboard} style={{ margin: "auto" }}>{t("shporta.vazhdoniTekArka")}</button>
                    </div>
                    <div>
                        {showFormModal ? (
                            <div className="modal fade" id="delete" style={{ zIndex: "100000" }}>
                                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                    <div className="modal-content">
                                        <div className="modal-header" style={{ backgroundColor: "white" }}>
                                            <button className="btn-close" data-bs-dismiss="modal" style={{ backgroundColor: "white", color: "red" }}></button>
                                        </div>
                                        <div className="modal-body" style={{ backgroundColor: "white", color: "#771923", minHeight: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <form onSubmit={handleSubmit}>
                                                <div className="mb-3">
                                                    <label htmlFor="firstName" className="form-label">{t("dashboard.emri")}:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="firstName"
                                                        name="firstName"
                                                        value={formData.firstName}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="lastName" className="form-label">{t("dashboard.mbiemri")}:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="lastName"
                                                        name="lastName"
                                                        value={formData.lastName}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="phoneNumber" className="form-label">{t("dashboard.telefoni")}:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="phoneNumber"
                                                        name="phoneNumber"
                                                        value={formData.phoneNumber}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="city" className="form-label">{t("dashboard.qyteti")}:</label>
                                                    {/* <input
                                                        type="text"
                                                        className="form-control"
                                                        id="city"
                                                        name="city"
                                                        value={formData.city}
                                                        onChange={handleInputChange}
                                                        required
                                                    /> */}
                                                    {
                                                        (country == "Kosove") ?
                                                            <select className="form-select"
                                                                id="city"
                                                                name="city"
                                                                value={formData.city}
                                                                onChange={handleInputChange}>
                                                                {
                                                                    kosovaCities.map((city, index) => <option key={index} value={city}>{city}</option>
                                                                    )
                                                                }
                                                            </select> :
                                                            <select className="form-select"
                                                                id="city"
                                                                name="city"
                                                                value={formData.city}
                                                                onChange={handleInputChange}>
                                                                {
                                                                    albanianCities.map((city, index) => <option key={index} value={city}>{city}</option>
                                                                    )
                                                                }

                                                            </select>

                                                    }
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="address" className="form-label">{t("dashboard.adresa")}:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="address"
                                                        name="address"
                                                        value={formData.address}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                </div>


                                                <button type="submit" className="btn btn-primary" style={{ backgroundColor: "#771923", color: "white", width: "120px", marginTop: "20px" }} data-bs-dismiss="modal">Blej:</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="modal fade" id="delete" style={{ zIndex: "100000" }}>
                                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                    <div className="modal-content">
                                        <div className="modal-header" style={{ backgroundColor: "white" }}>
                                            <button className="btn-close" data-bs-dismiss="modal" style={{ backgroundColor: "white", color: "red" }}></button>
                                        </div>
                                        <div className="modal-body" style={{ backgroundColor: "white", color: "#771923", fontSize: "200px", minHeight: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <h3 style={{ color: "black" }}>{t("shporta.regjistohuniText")}</h3>
                                        </div>
                                        <div className="modal-footer" id="footer" style={{ backgroundColor: "white", display: "flex", justifyContent: "center" }}>
                                            <button className="btn" style={{ backgroundColor: "green", color: "white" }} data-bs-dismiss="modal" onClick={handleRegister}>
                                                {t("shporta.registerButton")}
                                            </button>
                                            <button className="btn" style={{ backgroundColor: "#771923", color: "white" }} data-bs-dismiss="modal">
                                                {t("shporta.closeButton")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );

}
