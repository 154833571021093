import React from 'react';
import './ConfirmDialog.css'; // Make sure to style your dialog appropriately

const ConfirmDialog = ({ show, onClose, onConfirm, message }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="confirm-dialog">
            <div className="confirm-dialog-content">
                <p>{message}</p>
                <div className="confirm-dialog-actions">
                    <button onClick={onClose} className="cancel-button">Jo</button>
                    <button onClick={onConfirm} className="confirm-button">Po</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmDialog;
