// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


@media screen and (max-width: 700px) {

 .banner_main__owHsI{
    height: 600px !important;
 }
  
    
}

.banner_buton__O9-G9{
    margin-top: 3%;
}

/* 
.main{
     margin-top:220px !important;
     padding-top: 220px !important;
     
} */


@media screen and (max-width: 960px) {

    /* .main{
        position:absolute !important;
        width:100% !important;
        top:0 !important;   } */
    
}

#banner_banner__O9pfC {
    margin-top: 100px; /* Adjust as needed based on your navbar height */
    /* Other carousel styles */
}`, "",{"version":3,"sources":["webpack://./src/components/Banner/banner.module.css"],"names":[],"mappings":";;;AAGA;;CAEC;IACG,wBAAwB;CAC3B;;;AAGD;;AAEA;IACI,cAAc;AAClB;;AAEA;;;;;GAKG;;;AAGH;;IAEI;;;+BAG2B;;AAE/B;;AAEA;IACI,iBAAiB,EAAE,iDAAiD;IACpE,0BAA0B;AAC9B","sourcesContent":["\n\n\n@media screen and (max-width: 700px) {\n\n .main{\n    height: 600px !important;\n }\n  \n    \n}\n\n.buton{\n    margin-top: 3%;\n}\n\n/* \n.main{\n     margin-top:220px !important;\n     padding-top: 220px !important;\n     \n} */\n\n\n@media screen and (max-width: 960px) {\n\n    /* .main{\n        position:absolute !important;\n        width:100% !important;\n        top:0 !important;   } */\n    \n}\n\n#banner {\n    margin-top: 100px; /* Adjust as needed based on your navbar height */\n    /* Other carousel styles */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `banner_main__owHsI`,
	"buton": `banner_buton__O9-G9`,
	"banner": `banner_banner__O9pfC`
};
export default ___CSS_LOADER_EXPORT___;
