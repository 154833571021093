// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Store_highlight__Ky9iU {
    font-family: "Antique";
    font-size: 60px;
    font-weight: 700;
    position: relative;
    top: 60px;
    padding-top: 0px;
    z-index: 1;
    /* color: transparent; */
    /* background-color: #e4d1d3; */

}

.Store_main__otXuG{
    margin-top: 200px;
}
.Store_backgr__OaGE9{
    background-color: #e4d1d3;
    height: 40px !important;
    width: 50% !important;
    margin-left: 42%;
    position: relative !important;
    top: 15px;
}

.Store_divText__2XtWA{
    text-align: start;
    width: 40% ;

}

.Store_history__YMgj8{
    display: flex !important;
    justify-content: center !important;
    margin-top: 40px;
}

.Store_image__lXLGP{
    width: 100%;
    height: 100%;
}

.Store_divImage__jc2gH{
    width: 450px;
    height: 450px;
}
@media screen and (max-width:1051px) {
    .Store_history__YMgj8{
        flex-direction: column !important;
    }

    .Store_backgr__OaGE9{
        width: 65% !important;
        margin-left: 32%;


    }

    .Store_highlight__Ky9iU{
        right: 30px;
    }

    .Store_divText__2XtWA{
        width:90% ;
        text-align: center;
        margin-left: 15px;

    }
    .Store_divImage__jc2gH{
        margin: auto;
        width: 90%;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Store/Store.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,SAAS;IACT,gBAAgB;IAChB,UAAU;IACV,wBAAwB;IACxB,+BAA+B;;AAEnC;;AAEA;IACI,iBAAiB;AACrB;AACA;IACI,yBAAyB;IACzB,uBAAuB;IACvB,qBAAqB;IACrB,gBAAgB;IAChB,6BAA6B;IAC7B,SAAS;AACb;;AAEA;IACI,iBAAiB;IACjB,WAAW;;AAEf;;AAEA;IACI,wBAAwB;IACxB,kCAAkC;IAClC,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;AACA;IACI;QACI,iCAAiC;IACrC;;IAEA;QACI,qBAAqB;QACrB,gBAAgB;;;IAGpB;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,UAAU;QACV,kBAAkB;QAClB,iBAAiB;;IAErB;IACA;QACI,YAAY;QACZ,UAAU;IACd;AACJ","sourcesContent":[".highlight {\n    font-family: \"Antique\";\n    font-size: 60px;\n    font-weight: 700;\n    position: relative;\n    top: 60px;\n    padding-top: 0px;\n    z-index: 1;\n    /* color: transparent; */\n    /* background-color: #e4d1d3; */\n\n}\n\n.main{\n    margin-top: 200px;\n}\n.backgr{\n    background-color: #e4d1d3;\n    height: 40px !important;\n    width: 50% !important;\n    margin-left: 42%;\n    position: relative !important;\n    top: 15px;\n}\n\n.divText{\n    text-align: start;\n    width: 40% ;\n\n}\n\n.history{\n    display: flex !important;\n    justify-content: center !important;\n    margin-top: 40px;\n}\n\n.image{\n    width: 100%;\n    height: 100%;\n}\n\n.divImage{\n    width: 450px;\n    height: 450px;\n}\n@media screen and (max-width:1051px) {\n    .history{\n        flex-direction: column !important;\n    }\n\n    .backgr{\n        width: 65% !important;\n        margin-left: 32%;\n\n\n    }\n\n    .highlight{\n        right: 30px;\n    }\n\n    .divText{\n        width:90% ;\n        text-align: center;\n        margin-left: 15px;\n\n    }\n    .divImage{\n        margin: auto;\n        width: 90%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlight": `Store_highlight__Ky9iU`,
	"main": `Store_main__otXuG`,
	"backgr": `Store_backgr__OaGE9`,
	"divText": `Store_divText__2XtWA`,
	"history": `Store_history__YMgj8`,
	"image": `Store_image__lXLGP`,
	"divImage": `Store_divImage__jc2gH`
};
export default ___CSS_LOADER_EXPORT___;
