// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
	font-family: Arial, Helvetica, sans-serif;
	box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/components/Review/App.css"],"names":[],"mappings":"AAAA;CACC,yCAAyC;CACzC,sBAAsB;AACvB","sourcesContent":["* {\n\tfont-family: Arial, Helvetica, sans-serif;\n\tbox-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
