// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductCategorized_hline__6lC0M{
    border: 0.4px solid black ;
    margin-top: 20px ;
    width: 70%;
    margin: auto;
    background-color: #ccc;

}


.ProductCategorized_main__5pT14{
    margin-top: 300px;
}

.ProductCategorized_categoryDescrioption__Mv6M7{
    margin-bottom: 30px;
}

.ProductCategorized_categoryType__BYoai{
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 23px;
    margin-top: 20px;
    margin-bottom: 20px;

}

.ProductCategorized_button__4XpYY{
    background-color: white;
    border: none;
    margin-left: 20px;
    margin-right: 20px;    
    
}


@media screen and (max-width:965px) {
    
.ProductCategorized_main__5pT14{
    margin-top: 200px !important;
}

.ProductCategorized_hline__6lC0M{
    width: 90%;
}
    
}`, "",{"version":3,"sources":["webpack://./src/pages/ProductCategorized/ProductCategorized.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,iBAAiB;IACjB,UAAU;IACV,YAAY;IACZ,sBAAsB;;AAE1B;;;AAGA;IACI,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;;AAEvB;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;;AAEtB;;;AAGA;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,UAAU;AACd;;AAEA","sourcesContent":[".hline{\n    border: 0.4px solid black ;\n    margin-top: 20px ;\n    width: 70%;\n    margin: auto;\n    background-color: #ccc;\n\n}\n\n\n.main{\n    margin-top: 300px;\n}\n\n.categoryDescrioption{\n    margin-bottom: 30px;\n}\n\n.categoryType{\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    font-size: 23px;\n    margin-top: 20px;\n    margin-bottom: 20px;\n\n}\n\n.button{\n    background-color: white;\n    border: none;\n    margin-left: 20px;\n    margin-right: 20px;    \n    \n}\n\n\n@media screen and (max-width:965px) {\n    \n.main{\n    margin-top: 200px !important;\n}\n\n.hline{\n    width: 90%;\n}\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hline": `ProductCategorized_hline__6lC0M`,
	"main": `ProductCategorized_main__5pT14`,
	"categoryDescrioption": `ProductCategorized_categoryDescrioption__Mv6M7`,
	"categoryType": `ProductCategorized_categoryType__BYoai`,
	"button": `ProductCategorized_button__4XpYY`
};
export default ___CSS_LOADER_EXPORT___;
