import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import NavbarComponent from './components/NavbarComponent';
import Login from './pages/Login';
import Register from './pages/Register';
import Home from './pages/Home/Home';
import SpecificProduct from './pages/Product/SpecificProduct';
import Footer from './components/Footer/Footer';
import Dashboard from './pages/Dashboard/Dashboard';
import ProductCategorized from './pages/ProductCategorized/ProductCategorized';
import AddProductForm from './pages/AddProduct/addProductForm';
import Modal from './pages/Modal/Modal';
import UserAccount from './pages/Account/UserAccount';
import Timeline from './pages/purchaseHistory/Timeline';
import AllProductsAdmin from './pages/AdminPage/AllProductsAdmin';
import TimelineAdmin from './pages/purchaseHistory/TimelineAdmin';
import { useCookies } from 'react-cookie';
import './App.css';
import Customization from './pages/Customization/Customization';
import Store from './pages/Store/Store';

function App() {
  const [isButtonClicked, setButtonClicked] = useState(true);
  const [productToDashboard, setProductToDashboard] = useState(0);
  const [cookies] = useCookies(['role']);

  function handleButtonClick(data) {
    setButtonClicked(data);
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'F1') {
        event.preventDefault(); // Prevent the default action of F1 (usually help)

        // Determine the URL based on the role cookie
        const url = cookies.role === 'Administrator'
          ? 'https://docs.google.com/document/d/1m-xlREZZgfgK-k5vuIPXjbZjZazHUBCltBxaeMcPKvc/edit'
          : 'https://docs.google.com/document/d/1h_po3yzKomJkCEn3u19WEOy9iPC5AoCLtp2JPzu0hhY/edit?usp=drive_link';

        window.open(url, '_blank'); // Open the PDF in a new tab
      }
    };

    // Add event listener for keydown events
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [cookies.role]);

  const updateProductToDashboard = (value) => {
    setProductToDashboard(value);
  };

  return (
    <div className={`${(isButtonClicked) ? `App` : `AppButtonClicked`}`}>
      <div>{isButtonClicked}</div>
      <header className={`${(isButtonClicked) ? `headeriFixed` : `headeriAbsolut`}`}>
        <NavbarComponent message={handleButtonClick} prodNumber={productToDashboard} />
      </header>
      <section className={`${isButtonClicked ? `displaySection` : `dontDisplaySection`}`} style={{ minHeight: "42.4vh" }}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/account' element={<UserAccount />} />
          <Route path='/store' element={<Store></Store>}></Route>
          <Route path='/customization' element={<Customization></Customization>}></Route>
          <Route path='/product/:id' element={<SpecificProduct updateProductToDashboard={updateProductToDashboard} />} />
          <Route path="/productCategory/:category" element={<ProductCategorized />}>
            <Route path=':categoryFilter' element={<ProductCategorized />} />
          </Route>
          <Route path='/addNewProduct' element={<AddProductForm />} />
          <Route path='/dashboard' element={<Dashboard updateProductToDashboard={updateProductToDashboard} />} >
            <Route path="modal" element={<Modal />} />
          </Route>
          <Route path="/allProductsAdmin" element={<AllProductsAdmin />} />
          <Route path='/purchaseHistoryUser' element={<Timeline />} />
          <Route path='/purchaseHistoryAdmin' element={<TimelineAdmin />} />
        </Routes>
      </section>
      <footer className={`${isButtonClicked ? `displaySection` : `dontDisplaySection`}`} style={{ position: "absolute", width: "100%" }}>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
