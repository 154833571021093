import React from 'react';

export default function Modal() {
   
    return (
        <div>
            <div className="modal fade show">
                <div className="modal-content">
                    <div className="modal-body">
                        <div><i className="bi bi-trash"></i></div>
                        <h1>A jeni te sigurt</h1>
                        <p>Ne rast te fshirjes procesi nuk mund te rikthehet</p>
                    </div>
                    <div className="modal-footer">
                        <button >Delete</button>
                        <button className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}