import account from "./Account.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
export default function Register() {

    const [cookie, setCookies, removeCookies] = useCookies()
    const navigate = useNavigate()
    const { t, i18n } = useTranslation('global'); // Specify the namespace if needed
    const baseApiUrl = process.env.REACT_APP_BASE_API_URL;


    const registerFormik = useFormik({
        initialValues: {
            email: "",
            username: "",
            password: ""
        },
        validationSchema: yup.object({
            email: yup.string().required("Email is required").email("Invalid email"),
            username: yup.string().min(3, "Too short username").required("Username is required"),
            password: yup.string().required("Password is required").min(6, "Too short password"),
        }),
        onSubmit: values => {
            // Handle form submission logic here\
            axios.post(`${baseApiUrl}/signUp`, values)
                .then(rez => {
                    setCookies("token", rez.data.token)
                    setCookies("userId", rez.data.userId)
                    setCookies("email", rez.data.email)
                    setCookies("username", rez.data.username)
                    setCookies("role", rez.data.role)
                    navigate('/')
                })
                .catch(error => {
                    document.getElementById("aleradyInUse").classList.remove("d-none")
                });
        }
    });


    function handleEmailFocus() {

        document.getElementById("aleradyInUse").classList.add("d-none")


    }

    return (
        <div className={account.kryesori}>
            <div className={`${account.mian} container-fluid`}>
                <h2 className={account.text}>{t("register.regjistrohuni")}</h2>
                <form onSubmit={registerFormik.handleSubmit}>
                    <div className={account.inputDiv}>
                        <label className={`form-label ${account.data}`}>{t("register.emaili")}</label>
                        <div>
                            <input type="text" onChange={registerFormik.handleChange} name="email" onFocus={handleEmailFocus} value={registerFormik.values.email} />
                            {registerFormik.errors.email && <div className={`${account.error}`}>{registerFormik.errors.email}</div>}
                        </div>
                    </div>
                    <div className={account.inputDiv}>
                        <label className={`form-label ${account.data}`}>{t("register.perdoruesi")}</label>
                        <div>
                            <input type="text" onChange={registerFormik.handleChange} name="username" value={registerFormik.values.username} />
                            {registerFormik.errors.username && <div className={`${account.error}`}>{registerFormik.errors.username}</div>}
                        </div>
                    </div>
                    <div className={account.inputDiv}>
                        <label className={`form-label ${account.data}`}>{t("register.fjalkalimi")}</label>
                        <div>
                            <input type="password" onChange={registerFormik.handleChange} name="password" value={registerFormik.values.password} />
                            {registerFormik.errors.password && <div className={`${account.error}`}>{registerFormik.errors.password}</div>}
                        </div>
                    </div>
                    <div className="d-none" id="aleradyInUse" style={{ marginTop: "15px", marginBottom: "-15px", color: "#771923", fontSize: "18px", fontWeight: "bold" }}>{t("register.egziston")}</div>
                    <div className={account.buttonDiv}>
                        <button className={account.link} type="submit" >
                            {t("register.regjistrohuni")}
                        </button>
                        <Link to="/login" style={{color:"white", textDecoration:"none"}} className={account.link}>
                            {t("register.keniLlogari")}
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    );
}
