import axios from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import style from "./AllProductsAdmin.module.css"
import { Link } from "react-router-dom";

export default function AllProductsAdmin() {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cookies, setCookies, removeCookies] = useCookies();
    const token = cookies["token"];
    const [refresh, setRefresh] = useState(false);
    const navigate = useNavigate();
    const [textToDisplay, setTextToDisplay] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filterProduct, setFilterProduct] = useState([])
    const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

    const handleSearch = (e) => {
        const value = e.target.value
        console.log("vlaue " + (e.target.value == ""))
        if (value == "") {
            setProducts(filterProduct)
        }
        else {
            // setSearchQuery(e.target.value);
            filterProducts(value, e.target.id)
        }


    };

    // const filteredProducts = products.filter(item =>
    //     item.ProductName.toLowerCase().includes(searchQuery.toLowerCase())
    // );


    function filterProducts(value, type) {
        console.log("type " + type)
        const productFilter = filterProduct.filter(item =>
            item[type].toLowerCase() === value.toLowerCase()
        );

        setProducts(productFilter);
        console.log("madhesia " + productFilter.length)

    }

    useEffect(() => {
        getAllProducts();
    }, [refresh]);

    async function getAllProducts() {
        try {
            const response = await axios.get(`${baseApiUrl}/products`);
            setFilterProduct(response.data[0])
            setProducts(response.data[0]);
            setLoading(false);
        } catch (error) {
            console.error("There was an error fetching the products!", error);
            setLoading(false);
        }
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    function handleButtonAfterDelete() {
        setRefresh(!refresh);
        display();
    }

    function display() {
        document.getElementById("closeWindow").classList.add("d-none");
        document.getElementById("informWindow").classList.remove("d-none");
        document.getElementById("footer").classList.remove("d-none");
    }

    async function handleDeleteProduct(productId) {
        console.log("blalblb", productId)
        axios
            .delete(`${baseApiUrl}/deleteProduct/${productId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .then((rez) => {
                document.getElementById("informWindow").classList.add("d-none");
                document.getElementById("footer").classList.add("d-none");
                document.getElementById("closeWindow").classList.remove("d-none");
            });
    }

    function handleMouseEnter(e) {
        const attribute = e.target.getAttribute("name");
        if (attribute) {
            const element = document.getElementById(attribute);
            if (element) {
                element.classList.remove("d-none");
            } else {
                console.error("Element not found with id:", attribute);
            }
        } else {
            console.error("Attribute 'name' not found on element:", e.target);
        }
    }

    function handleMouseLeave(e) {
        const attribute = e.target.getAttribute("name");
        if (attribute) {
            const element = document.getElementById(attribute);
            if (element) {
                element.classList.add("d-none");
            } else {
                console.error("Element not found with id:", attribute);
            }
        } else {
            console.error("Attribute 'name' not found on element:", e.target);
        }
    }

    function handleInputShow(e) {
        const attribute = e.currentTarget.id;
        const inputs = document.getElementsByClassName("inputi");
        const element = document.getElementById(attribute + "text");
        if (!element) {
            console.error("Element not found with id:", attribute + "text");
            return;
        }
        for (let i = 0; i < inputs.length; i++) {
            if (!inputs[i].classList.contains(attribute)) {
                inputs[i].classList.add("d-none");
                textToDisplay?.classList.remove("d-none");
                continue;
            }
            inputs[i].classList.remove("d-none");
            setTextToDisplay(element);
            element.classList.add("d-none");
        }
    }

    function addAllProducts() {
        setProducts(filterProduct);
    }

    return (
        <div className="container-fluid">
            <table className="table table-bordered table-hover">
                <thead>
                    <tr style={{ height: "60px" }}>
                        <th><button onClick={addAllProducts} style={{ border: "none", width: "100%", height: "100%" }}>#</button></th>
                        <th>Fotot</th>
                        <th name="name" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <span id="nametext">Emri</span>
                            <span id="name" className="d-none" style={{ float: "right" }} onClick={handleInputShow}>
                                <i className="bi bi-search" style={{ fontSize: "20px" }}></i>
                            </span>
                            <input
                                id="ProductName"
                                className="name inputi d-none"
                                type="text"
                                placeholder="Search products"
                                onChange={handleSearch}
                            />
                        </th>
                        <th name="kategoria" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <span id="kategoriatext">Kategoria</span>
                            <span className="d-none" id="kategoria" style={{ float: "right" }} onClick={handleInputShow}>
                                <i className="bi bi-search" style={{ fontSize: "20px" }}></i>
                            </span>
                            <input
                                id="CategoryName"
                                className="kategoria inputi d-none"
                                type="text"
                                placeholder="Search products"
                                onChange={handleSearch}
                            />
                        </th>
                        <th name="brendi" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <span id="brenditext">Brendi</span>
                            <span className="d-none" id="brendi" style={{ float: "right" }} onClick={handleInputShow}>
                                <i className="bi bi-search" style={{ fontSize: "20px" }}></i>
                            </span>
                            <input
                                id="BrandName"
                                className="brendi inputi d-none"
                                type="text"
                                placeholder="Search products"
                                onChange={handleSearch}
                            />
                        </th>
                        <th name="materiali" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <span id="materialitext">Materiali</span>
                            <span className="d-none" id="materiali" style={{ float: "right" }} onClick={handleInputShow}>
                                <i className="bi bi-search" style={{ fontSize: "20px" }}></i>
                            </span>
                            <input
                                id="MaterialName"
                                className="materiali inputi d-none"
                                type="text"
                                placeholder="Search products"
                                onChange={handleSearch}
                            />
                        </th>
                        <th name="pesha" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <span id="peshatext">Pesha</span>
                            <span className="d-none" id="pesha" style={{ float: "right" }} onClick={handleInputShow}>
                                <i className="bi bi-search" style={{ fontSize: "20px" }}></i>
                            </span>
                            <input className="pesha inputi d-none" type="text" placeholder="Search products" />
                        </th>
                        <th name="stoku" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <span id="stokutext">Stoku</span>
                            <span className="d-none" id="stoku" style={{ float: "right" }} onClick={handleInputShow}>
                                <i className="bi bi-search" style={{ fontSize: "20px" }}></i>
                            </span>
                            <input className="stoku inputi d-none" type="text" placeholder="Search products" />
                        </th>
                        <th name="cmimi" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <span id="cmimitext">Cmimi</span>
                            <span className="d-none" id="cmimi" style={{ float: "right" }} onClick={handleInputShow}>
                                <i className="bi bi-search" style={{ fontSize: "20px" }}></i>
                            </span>
                            <input className="cmimi inputi d-none" type="text" placeholder="Search products" />
                        </th>
                        <th>Ridakto</th>
                        <th>Fshij</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((item, index) => (
                        <tr key={item.id || index} style={{ height: "60px" }}>
                            <td>{index + 1}</td>
                            <td>
                                {item?.ProductImages?.split(",").map((itemImg, imgIndex) => (
                                    <img key={imgIndex} src={itemImg} alt={`Product ${index + 1} Image ${imgIndex + 1}`} style={{ width: "40px", height: "40px" }} />
                                ))}
                            </td>
                            <td style={{ textAlign: "center", verticalAlign: "middle" }}>{item.ProductName}</td>
                            <td style={{ textAlign: "center", verticalAlign: "middle" }}>{item.CategoryName}</td>
                            <td style={{ textAlign: "center", verticalAlign: "middle" }}>{item.BrandName}</td>
                            <td style={{ textAlign: "center", verticalAlign: "middle" }}>{item.MaterialName}</td>
                            <td style={{ textAlign: "center", verticalAlign: "middle" }}>{item.Weight}</td>
                            <td style={{ textAlign: "center", verticalAlign: "middle" }}>{item.StockQuantity}</td>
                            <td style={{ textAlign: "end", verticalAlign: "middle" }}>{item.Price}</td>
                            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                <Link to={`/product/${item.ProductID}`}> <button className="btn btn-warning" style={{ fontSize: "18px" }}>
                                    <i className="bi bi-recycle"></i>
                                </button></Link>
                            </td>
                            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                <div>
                                    <button
                                        className="btn btn-danger"
                                        style={{ fontSize: "18px" }}
                                        data-bs-target="#delete"
                                        data-bs-toggle="modal"
                                        onClick={() => handleDeleteProduct(item.ProductID)}
                                    >
                                        <i className="bi bi-trash"></i>
                                    </button>
                                    <div className="modal fade" id="delete" style={{ zIndex: "100000" }}>
                                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                            <div className="modal-content">
                                                <div className="modal-header" style={{ backgroundColor: "white" }}>
                                                    <button className="btn-close" data-bs-dismiss="modal" style={{ backgroundColor: "white", color: "red" }}></button>
                                                </div>
                                                <div className="modal-body" style={{ backgroundColor: "white", color: "#771923", fontSize: "200px", minHeight: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div id="informWindow">
                                                        <i className="bi bi-trash"></i>
                                                        <h3 style={{ color: "black" }}>Produkti i fshire nuk mund te kthehet!</h3>
                                                    </div>
                                                    <div className="d-none" id="closeWindow">
                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                                            <h3 style={{ color: "black" }}>
                                                                Produkti u fshie me sukses<span style={{ color: "green" }}>
                                                                    <i className="bi bi-check-circle-fill"></i>
                                                                </span>
                                                            </h3>
                                                            <button className="btn" style={{ backgroundColor: "#771923", color: "white", marginTop: "30px" }} onClick={handleButtonAfterDelete} data-bs-dismiss="modal">
                                                                Mbylleni
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer" id="footer" style={{ backgroundColor: "white", display: "flex", justifyContent: "center" }}>
                                                    <button className="btn" style={{ backgroundColor: "green", color: "white" }} data-bs-dismiss="modal">
                                                        Anulo Fshijen
                                                    </button>
                                                    <button className="btn" style={{ backgroundColor: "#771923", color: "white" }} onClick={() => handleDeleteProduct(item.ProductID)}>
                                                        Fshije Produktin
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
