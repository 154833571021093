import React, { useState } from 'react';
import { Button, Spinner, Alert } from 'react-bootstrap';
import styles from './addProductForm.module.css';
import axios from 'axios';
import LoadingIndicator from 'react-loading-indicator';
import { useCookies } from 'react-cookie';
import Cookies from 'js-cookie';

const AddProductForm = () => {
  const [cookies] = useCookies(['token']);
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [stockQuantity, setStockQuantity] = useState('10');
  const [weight, setWeight] = useState('0');
  const [rating, setRating] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [brandName, setBrandName] = useState('--');
  const [materialName, setMaterialName] = useState('Argjend');
  const [gender, setGender] = useState('undefined'); // State for gender
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState('danger');
  const categories = ['Unaza', 'Byzylyke', 'Varse', 'Komplete', 'Figura', 'Vath', 'Personalizime', "Burme", "Manzheta"];
  const [selectedCategory, setSelectedCategory] = useState(categories[0]); // Set default category
  const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setCategoryName(event.target.value); // Ensure categoryName state is updated

  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
  };

  const handleSubmit = async (e) => {
    console.log("jajajajajajajajaja ", weight)
    e.preventDefault();
    setLoading(true);
    setShowAlert(false);

    // Map displayed gender values to appropriate form data values
    const genderMapping = {
      'Mashkull': 'male',
      'Femër': 'female',
      'Unisex': 'unisex',
      'undefined': '' // Ensure there's a fallback for undefined
    };

    const formData = {
      productName: productName,
      productPrice: parseFloat(productPrice),
      StockQuantity: parseInt(stockQuantity),
      Weight: parseFloat(weight),
      Rating: parseFloat(rating),
      CategoryName: selectedCategory,
      BrandName: brandName,
      MaterialName: materialName,
      gender: genderMapping[gender], // Map gender value
    };

    try {
      const token = Cookies.get('token'); // Get the token from the custom Cookies module
      if (!token) {
        throw new Error('No token found in cookies');
      }

      const response = await axios.post(`${baseApiUrl}/insertProduct`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      });

      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }
      console.log('GENDERRR ' + formData.gender);
      console.log('TYPEEEE ' + formData.CategoryName);

      const responseData = response.data;
      const productId = responseData.productId;

      const imageFormData = new FormData();

      images.forEach((image) => {
        imageFormData.append('images', image);
      });
      imageFormData.append('product_id', productId);

      const uploadResponse = await axios.post(`${baseApiUrl}/upload`, imageFormData, {
        headers: {
          'Authorization': token,
          'Content-Type': 'multipart/form-data'
        }
      });

      if (uploadResponse.status !== 200) {
        throw new Error('Failed to upload images');
      }

      console.log('Images uploaded successfully');

      // Reset form fields
      setProductName('');
      setProductPrice('');
      setStockQuantity('');
      setWeight('');
      setRating('');
      setCategoryName('');
      setBrandName('');
      setMaterialName('');
      setGender('undefined'); // Reset gender
      setImages([]);
      setAlertMessage('Produkti u shtua me sukses!');
      setShowAlert(true);
      setAlertVariant('success');
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setAlertMessage('Not authorized');
        setShowAlert(true);
        setAlertVariant('danger');
      } else {
        setAlertMessage('Ndodhi një gabim! Ju lutem provoni përsëri.');
        setShowAlert(true);
        setAlertVariant('danger');
        console.error('Error:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`${styles.main} container-fluid`}>
      <h2 className={`${styles.text}`}>Shto një produkt</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        {loading && <LoadingIndicator />} {/* Loading spinner */}
        <div className={styles.inputRow}>
          <label className={`form-label ${styles.data}`}>Emri produktit:</label>
          <input type="text" className={styles.inputField} value={productName} onChange={(e) => setProductName(e.target.value)} />
        </div>
        <div className={styles.inputRow}>
          <label className={`form-label ${styles.data}`}>Çmimi i produktit:</label>
          <input type="number" className={styles.inputField} value={productPrice} onChange={(e) => setProductPrice(e.target.value)} />
        </div>
        <div className={styles.inputRow}>
          <label className={`form-label ${styles.data}`}>Sasia në stok:</label>
          <input type="number" className={styles.inputField} value={stockQuantity} onChange={(e) => setStockQuantity((e.target.value == ""?10:e.target.value))} />
        </div>
        <div className={styles.inputRow}>
          <label className={`form-label ${styles.data}`}>Pesha:</label>
          <input type="number" className={styles.inputField} value={weight} onChange={(e) => setWeight((e.target.value == ""? "--":e.target.value))} />
        </div>
        <div className={styles.inputRow}>
          <label className={`form-label ${styles.data}`}>Kategoria:</label>
          <select
            className={styles.inputField}
            value={selectedCategory}
            onChange={handleCategoryChange}
          >
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.inputRow}>
          <label className={`form-label ${styles.data}`}>Brendi:</label>
          <input type="text" className={styles.inputField} value={brandName} onChange={(e) => setBrandName(e.target.value)} />
        </div>
        <div className={styles.inputRow}>
          <label className={`form-label ${styles.data}`}>Materiali:</label>
          <input type="text" className={styles.inputField} value={materialName} onChange={(e) => setMaterialName(e.target.value)} />
        </div>
        <div className={styles.inputRow}>
          <label className={`form-label ${styles.data}`}>Gjinia:</label>
          <div className={`${styles.inputField} ${styles.genderRadioGroup}`}>
            <label>
              <input type="radio" value="Mashkull" checked={gender === 'Mashkull'} onChange={(e) => setGender(e.target.value)} />
              Mashkull
            </label>
            <label>
              <input type="radio" value="Femër" checked={gender === 'Femër'} onChange={(e) => setGender(e.target.value)} />
              Femër
            </label>
            <label>
              <input type="radio" value="Unisex" checked={gender === 'Unisex'} onChange={(e) => setGender(e.target.value)} />
              Unisex
            </label>
          </div>
        </div>

        <div className={styles.inputRow}>
          <label className={`form-label ${styles.data}`}>Fotot:</label>
          <div className={styles.inputField}>
            <input name='' type="file" multiple onChange={handleImageChange} />
          </div>
          {Array.isArray(images) && images.map((image, index) => (
            <div key={index} className={`${styles.imageDiv} mt-2`}>
              <img src={URL.createObjectURL(image)} alt={`Image ${index}`} width="100" height="100" />
            </div>
          ))}
        </div>
        {showAlert && <Alert variant={alertVariant} onClose={() => setShowAlert(false)} dismissible>{alertMessage}</Alert>}

        <div className={`${styles.buttonDiv}`}>
          <button type="submit" className={`${styles.link}`} onClick={handleSubmit} disabled={loading}>
            Shto produktin
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddProductForm;
