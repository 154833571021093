import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import style from "./ProductCategorized.module.css"
import { prod, getLength } from "../Home/Produkte.js"
import axios from "axios"
import Card from "../../components/Card/Card"
import Pagination from "../../components/Paggination/Pagination"
import { Link } from "react-router-dom"
import { handleResizeWindow } from "../../Functions/HandleResizeWindow.js"
import { useCookies } from "react-cookie"
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function ProductCategorized() {
    const params = useParams()

    const obj = {
        
    }

    const category = params.category
    console.log("")
    const categoryFilter = params.categoryFilter
    console.log("JSJSJJS", categoryFilter)
    const [cookies, setCookies, removeCookies] = useCookies()
    const { t, i18n } = useTranslation('global'); // Specify the namespace if needed

    const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

    const [page, setPage] = useState(1);
    const [limit, setLimits] = useState(9);
    const [products, setProducts] = useState([])
    const totalPage = Math.ceil(getLength(products) / limit)
    function handlePageChange(value) {

        switch (value) {
            case "R": {
                window.scrollTo({
                    top: 100,
                })
                if (page != totalPage)
                    setPage(page + 1);
              

            }
                break;
            case "L": {
                window.scrollTo({
                    top: 100,
                })
                if (page != 1)
                    setPage(page - 1);

            }
                break;
            default:
                window.scrollTo({
                    top: 100,
                })
                setPage(value);
        }

    }

    useEffect(() => {

        function handleResize() {
            setLimits(handleResizeWindow())

        }
        handleResize()

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (categoryFilter == undefined) {
            axios.post(`${baseApiUrl}/specificGenderProducts`, {
                "gender": category

            }).then(res => setProducts(res.data[0]))
        }
        else {
            axios.post(`${baseApiUrl}/specificGenderProducts`, {
                "gender": category,
                "categoryFilters": [categoryFilter]

            }).then(res => setProducts(res.data[0]))
            setPage(1)
        }
    }, [category, categoryFilter])
    return (
        <div className={` ${style.main} container-fluid`}>
            <h1 className={`${style.categoryDescrioption}`} style={{ display: category == "female" ? "block" : "none" }}>{t("navBar.femra")}</h1>
            <h1 className={`${style.categoryDescrioption}`} style={{ display: category == "male" ? "block" : "none" }}>{t("navBar.meshkuj")}</h1>
            <h1 className={`${style.categoryDescrioption}`} style={{ display: (categoryFilter == "Unaza"   && category == "unisex") ? "block" : "none" }}>{t("navBar.unaze")}</h1>
            <h1 className={`${style.categoryDescrioption}`} style={{ display: categoryFilter == "Komplete" ? "block" : "none" }}>{t("navBar.komplete")}</h1>
            <h1 className={`${style.categoryDescrioption}`} style={{ display: categoryFilter == "Byzylyk" ? "block" : "none" }}>{t("navBar.byzylyk")}</h1>
            <h1 className={`${style.categoryDescrioption}`} style={{ display: categoryFilter == "Burme" ? "block" : "none" }}>{t("navBar.burme")}</h1>
            <h1 className={`${style.categoryDescrioption}`} style={{ display: categoryFilter == "Personalizime" ? "block" : "none" }}>{t("navBar.personalizime")}</h1>





            <hr className={`${style.hline}`}></hr>
            {/* <div className={style.categoryType} style={{ display: categoryFilter == "Personalizime" ? "none" : "block" }}>
                <Link to={`/productCategory/${category}/Orë`}><button className={style.button}>{t("navBar.ore")}</button></Link>
                <Link to={`/productCategory/${category}/Byzylyk`} style={{ display: category == "male" ? "none" : "" }}><button className={style.button}>{t("navBar.byzylyk")}</button></Link>
                <Link to={`/productCategory/${category}/Unazë`}><button className={style.button}>{t("navBar.unaze")}</button></Link>
                <Link to={`/productCategory/${category}/Varse`}><button className={style.button}>{t("navBar.varse")}</button></Link>
            </div> */}
            <div className={style.categoryType} style={{ display: category == "female" ? "block" : "none" }}>
                <Link to={`/productCategory/${category}/Varse`}><button className={style.button}>{t("navBar.varse")}</button></Link>
                <Link to={`/productCategory/${category}/Figura`}><button className={style.button}>{t("navBar.figura")}</button></Link>
                <Link to={`/productCategory/${category}/Unaza`}><button className={style.button}>{t("navBar.unaze")}</button></Link>
                <Link to={`/productCategory/${category}/Vath`}><button className={style.button}>{t("navBar.vath")}</button></Link>

            </div>
            <div className={style.categoryType} style={{ display: category == "male" ? "block" : "none" }}>
                <Link to={`/productCategory/${category}/Varse`}><button className={style.button}>{t("navBar.varse")}</button></Link>
                <Link to={`/productCategory/${category}/Figura`}><button className={style.button}>{t("navBar.figura")}</button></Link>
                <Link to={`/productCategory/${category}/Unaza`}><button className={style.button}>{t("navBar.unaze")}</button></Link>
                <Link to={`/productCategory/${category}/Manzheta`}><button className={style.button}>{t("navBar.manzheta")}</button></Link>
            </div>
            <div className={style.categoryType} style={{ display: (categoryFilter == "Unaza"  && category == "unisex") ? "block" : "none" }}>
                <Link to={`/productCategory/unisex/Burme`}><button className={style.button}>{t("navBar.burme")}</button></Link>
            </div>
            <hr className={`${style.hline}`} style={{ display:( categoryFilter == "Personalizime") ? "none" : "" }}></hr>
            <div>

                <Card produktet={prod(page, limit, products)}></Card>
                <Pagination totalPage={totalPage} page={page} limit={limit} siblings={1} onPageChange={handlePageChange}></Pagination>
            </div>
        </div>
    )
}