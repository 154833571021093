import { useEffect, useState } from "react";
import './App.css';
import { FaStar } from "react-icons/fa";

const colors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9"
    
};


export default function SpecificProductReview({raiting, size}){
    const [currentValue, setCurrentValue] = useState(0);
    const [hoverValue, setHoverValue] = useState(undefined);
    const stars = Array(5).fill(0)

    useEffect(()=>{
        setCurrentValue(raiting)
    },[raiting])
    return (
      <div style={{display:"inline"}}>
        <div >
          {stars.map((_, index) => {
            return (
              <FaStar
                key={index}
                size={size}
                color={(hoverValue || currentValue) > index ? colors.orange : colors.grey}
                style={{
                  marginRight: 10,
                  cursor: "pointer"
                }}
              />
            )
          })}
        </div>
      </div>
    );
  };
  
  
  