// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Account_container-fluid__vT7id{
    max-width: 90%;
}

.Account_kryesori__p-M1V{
   
}
.Account_error__aOgFn{
    color: #771923;
    text-align: start;
}

.Account_text__m55NC{
    text-align: center;
    font-weight: bold;
}

.Account_mian__HWvI\\+{
    background-color: #edeceb ;
    padding-bottom:30px ;
    padding-top: 20px;
    margin: auto;
    width: 600px !important;
    max-width: 90%; 
    margin-top: 270px !important;
    
}

.Account_inputDiv__4KtwE{
    margin-top: 20px;
}

form div input{
    width: 100%;
    border:  none;
    border-color: #edeceb;
    height: 40px;
    
}

form div input:focus{
    border: none;
}


.Account_link__AXCb7{
    background-color: #771923;
    width: 100%;
    border:  none;
    text-align: center;
    text-decoration: none;
    color: #d6d5d4;
    padding: 10px 10px;
    margin-top: 20px !important;
    border-radius: 20px;
    
}


.Account_buttonDiv__Okt6T{
    margin-top: 40px !important;
    display: flex !important;
    flex-direction: column;
}

.Account_data__UJidU{
    display: block;
    text-align:start;
}

form{
    margin: 20px;
}


@media screen and (max-width: 960px) {

    .Account_mian__HWvI\\+{
        margin-top: 170px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Account.module.css"],"names":[],"mappings":";AACA;IACI,cAAc;AAClB;;AAEA;;AAEA;AACA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,0BAA0B;IAC1B,oBAAoB;IACpB,iBAAiB;IACjB,YAAY;IACZ,uBAAuB;IACvB,cAAc;IACd,4BAA4B;;AAEhC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,qBAAqB;IACrB,YAAY;;AAEhB;;AAEA;IACI,YAAY;AAChB;;;AAGA;IACI,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,qBAAqB;IACrB,cAAc;IACd,kBAAkB;IAClB,2BAA2B;IAC3B,mBAAmB;;AAEvB;;;AAGA;IACI,2BAA2B;IAC3B,wBAAwB;IACxB,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;;AAGA;;IAEI;QACI,4BAA4B;IAChC;AACJ","sourcesContent":["\n.container-fluid{\n    max-width: 90%;\n}\n\n.kryesori{\n   \n}\n.error{\n    color: #771923;\n    text-align: start;\n}\n\n.text{\n    text-align: center;\n    font-weight: bold;\n}\n\n.mian{\n    background-color: #edeceb ;\n    padding-bottom:30px ;\n    padding-top: 20px;\n    margin: auto;\n    width: 600px !important;\n    max-width: 90%; \n    margin-top: 270px !important;\n    \n}\n\n.inputDiv{\n    margin-top: 20px;\n}\n\nform div input{\n    width: 100%;\n    border:  none;\n    border-color: #edeceb;\n    height: 40px;\n    \n}\n\nform div input:focus{\n    border: none;\n}\n\n\n.link{\n    background-color: #771923;\n    width: 100%;\n    border:  none;\n    text-align: center;\n    text-decoration: none;\n    color: #d6d5d4;\n    padding: 10px 10px;\n    margin-top: 20px !important;\n    border-radius: 20px;\n    \n}\n\n\n.buttonDiv{\n    margin-top: 40px !important;\n    display: flex !important;\n    flex-direction: column;\n}\n\n.data{\n    display: block;\n    text-align:start;\n}\n\nform{\n    margin: 20px;\n}\n\n\n@media screen and (max-width: 960px) {\n\n    .mian{\n        margin-top: 170px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container-fluid": `Account_container-fluid__vT7id`,
	"kryesori": `Account_kryesori__p-M1V`,
	"error": `Account_error__aOgFn`,
	"text": `Account_text__m55NC`,
	"mian": `Account_mian__HWvI+`,
	"inputDiv": `Account_inputDiv__4KtwE`,
	"link": `Account_link__AXCb7`,
	"buttonDiv": `Account_buttonDiv__Okt6T`,
	"data": `Account_data__UJidU`
};
export default ___CSS_LOADER_EXPORT___;
