import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import produkte from "../../ban.js";
import baneri from "./banner.module.css";
import axios from "axios";

export default function Banneri() {
    const [products, setProducts] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

    useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {
        axios.get(`${baseApiUrl}/offers`).then(rez => {
            const filteredProducts = rez.data[0].map(prod => ({
                ...prod,
                ProductImages: prod.ProductImages || ''  // Ensure ProductImages is not null or undefined
            }));
            setProducts(filteredProducts);
        }).catch(error => console.error('Failed to fetch products:', error));
    }

    function handleSlide(index) {
        setActiveIndex(index);
    }

    return (
        <div className={`container-fluid position-relative ${baneri.main}`} style={{ width: "100%" }}>
            <div id="banner" className="carousel carousel-dark" style={{ width: "100%" }} data-bs-ride="carousel">
                <div className="carousel-inner" style={{ zIndex: "0" }}>
                    {products.map((prod, index) => (
                        <div className={`carousel-item w-100 ${index === activeIndex ? "active" : ""}`} key={index} data-bs-interval="3000">
                            <Link to={`/product/${prod.ProductID}`}>
                                <img src={prod.ProductImages.split(",")[0]} className={`w-100 d-block ${baneri.img}`} style={{ height: "600px" }} alt="Product" />
                            </Link>
                            <div className="carousel-caption position-absolute translate-middle-x" style={{ top: "40px", right: "0px", color: "#771923", fontWeight: "bold" }}>
                                {/* Optional content here */}
                            </div>
                        </div>
                    ))}
                </div>
                <button className={`carousel-control-prev ${baneri.buton}`} style={{ width: "60px", zIndex: "0" }} data-bs-slide="prev" data-bs-target="#banner"><span className="carousel-control-prev-icon"></span></button>
                <button className={`carousel-control-next ${baneri.buton}`} style={{ width: "60px", zIndex: "0" }} data-bs-slide="next" data-bs-target="#banner"><span className="carousel-control-next-icon"></span></button>
                <div className="carousel-indicators">
                    {products.map((_, index) => (
                        <button
                            key={index}
                            className={index === activeIndex ? "active" : ""}
                            data-bs-slide-to={index}
                            data-bs-target="#banner"
                            style={{ backgroundColor: index === activeIndex ? "#771923" : "#771923" }}
                            onClick={() => handleSlide(index)}
                        ></button>
                    ))}
                </div>
            </div>
        </div>
    );
}
