import { useState } from 'react'; // Import useState hook
import { Link, useNavigate } from 'react-router-dom';
import account from './Account.module.css';
import * as yup from "yup";
import { useFormik } from 'formik';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useTranslation } from "react-i18next";
import i18next from "i18next";


export default function Login() {
  // Define state variables to store email and password
  const [cookies, setCookies, removeCookies] = useCookies()
  const [role, setRole] = useState()
  const [errorMessage, setErrorMessage] = useState("") // State variable for error message
  const navigate = useNavigate()
  const { t, i18n } = useTranslation('global'); // Specify the namespace if needed
  const baseApiUrl = process.env.REACT_APP_BASE_API_URL;


  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: yup.object({
      email: yup.string().required("Email is required").email("Invalid email"),
      password: yup.string().required("Password is required").min(4, "Too short password"),
    }),

    onSubmit: values => {
      axios.post(`${baseApiUrl}/logIn`, values)
        .then(rez => {
          setCookies("token", rez.data.token)
          setCookies("userId", rez.data.userId)
          setCookies("email", rez.data.email)
          setCookies("username", rez.data.username)
          setCookies("role", rez.data.role)
          navigate("/")
        })
        .catch(err => {
          // setErrorMessage("Keni gabuar kredencialet, ju lutem provoni përsëri!"); // Set error message
          document.getElementById("aleradyInUse").classList.remove("d-none")

        });
    }

  })

  function handleEmailFocus() {

    document.getElementById("aleradyInUse").classList.add("d-none")


}

  return (
    <div className={`${account.mian} container-fluid`}>
      <h2 className={`${account.text}`}>{t("register.hyni")}</h2>
      <form onSubmit={formik.handleSubmit}> {/* Attach handleSubmit to the form */}
        <div className={`${account.inputDiv}`}>
          <label className={`form-label ${account.data}`}>{t("register.emaili")}</label>
          <div>
            <input
              className=""
              type="text"
              name='email'
              value={formik.values.email}
              onChange={formik.handleChange} // Add onChange to update formik state
              onFocus={handleEmailFocus} 
            />
            {formik.errors.email && <div className={`${account.error}`}>{formik.errors.email}</div>}

          </div>
        </div>
        <div className={`${account.inputDiv}`}>
          <label className={`form-label ${account.data}`}>{t("register.fjalkalimi")}</label>
          <div>
            <input
              className=""
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange} // Add onChange to update formik state
              onFocus={handleEmailFocus} 
            />
            {formik.errors.password && <div className={`${account.error}`}>{formik.errors.password}</div>}
          </div>
        </div>

        {errorMessage && ( // Conditionally render the error message
          <div className={`${account.errorMessage}`}>
            {errorMessage}
          </div>
        )}
      <div className="d-none" id="aleradyInUse" style={{ marginTop: "15px", marginBottom: "-15px", color: "#771923", fontSize: "18px", fontWeight: "bold" }}>{t("register.kredencialet")}</div>

        <div className={`${account.buttonDiv}`}>
          <button className={`${account.link}`} type="submit">{t("register.hyni")}</button> {/* Change onClick to type="submit" */}
          <Link to="/register" style={{ color: "white", textDecoration: "none" }} className={`${account.link}`}>{t("register.regjistrohuni")}</Link>
        </div>
      </form>
    </div>
  );
}
