

export function handleResizeWindow(){

    if (window.innerWidth < 900) {
        return 6
    } else {
        return 9
    }

}