import React, { useEffect, useState } from 'react';
import './TimelineAdmin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faShoppingCart, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'; // Import the shopping cart icon
import axios from 'axios';
import Cookies from 'js-cookie';

const TimelineAdmin = () => {
    const [purchases, setPurchases] = useState([]);
    const [loading, setLoading] = useState(false);
    const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

    const fetchHistory = async () => {
        setLoading(true);
        try {
            const token = Cookies.get('token'); // Get the token from the custom Cookies module
            if (!token) {
                throw new Error('No token found in cookies');
            }

            const response = await axios.get(`${baseApiUrl}/buyHistoryAdmin`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            });

            if (response.status !== 200) {
                throw new Error('Network response was not ok');
            }

            // Extract the purchases data from the response
            const purchasesData = response.data[0];

            // Set the purchases state with the fetched data
            setPurchases(purchasesData);
        } catch (error) {
            console.error('There was an error!', error);

        } finally {
            setLoading(false);
        }
    };

    const markAsFinished = async (id) => {
        try {
            const token = Cookies.get('token'); // Get the token from the custom Cookies module
            if (!token) {
                throw new Error('No token found in cookies');
            }

            await axios.get(`${baseApiUrl}/markFinished/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            });

            // Update the status of the specific purchase locally
            setPurchases(prevPurchases =>
                prevPurchases.map(purchase =>
                    purchase.PurchaseID === id ? { ...purchase, Status: 'finished' } : purchase
                )
            );
        } catch (error) {
            console.error('There was an error!', error);
        }
    };

    const markAsCanceled = async (id) => {
        try {
            const token = Cookies.get('token'); // Get the token from the custom Cookies module
            if (!token) {
                throw new Error('No token found in cookies');
            }

            await axios.get(`${baseApiUrl}/markCanceled/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            });

            // Update the status of the specific purchase locally
            setPurchases(prevPurchases =>
                prevPurchases.map(purchase =>
                    purchase.PurchaseID === id ? { ...purchase, Status: 'canceled' } : purchase
                )
            );
        } catch (error) {
            console.error('There was an error!', error);
        }
    };

    useEffect(() => {
        // Fetch data when the component mounts
        fetchHistory();
    }, []);

    return (
        <div className="timeline">
            {purchases.map((purchase, index) => {
                const date = new Date(purchase.Timestamp);
                const day = date.getDate();
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
                const year = date.getFullYear();
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const imageUrls = purchase.URLs.split(',');
                const price = (purchase.Price * purchase.Quantity).toFixed(2);
                const status = purchase.Status;
                const email = purchase.UserEmail;
                const address = purchase.UserAddress;
                return (
                    <div key={index} className={`event ${status === 'in progress' ? 'in-progress' : status}`}>
                        <div className={`circle ${status === 'in progress' ? 'in-progress' : status}`}></div>
                        <div className="date">
                            <span>{`${day}/${month}/${year}`}</span>
                            <br />
                            <span>{`${hours}:${minutes}`}</span>
                            <div className="icon">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </div>
                        </div>
                        <div className='content'>
                            <div className={`content ${status === 'in progress' ? 'in-progress' : status}`}>
                                <div className="status">{status === 'finished' ? 'Blerja e kompletuar' : status === 'in progress' ? 'Blerja në proces' : 'Blerja është anuluar'}</div>
                            </div>

                            <div className='thirdRow'>
                                <div className="field">Sasia: {purchase.Quantity}</div>

                                <div className="image-container">
                                    {imageUrls.map((url, idx) => (
                                        <img key={idx} src={url} alt={`Product ${idx}`} className="product-image" />
                                    ))}
                                </div>
                            </div>
                            <div className='secondRow'>
                                <div className="field">{email}</div>
                                <div className="field">Qyteti: {purchase.City}</div>
                            </div>
                        </div>
                        <div className="rightColumn">
                            <div className="price">
                                <div className="price-text">{price}€</div>
                            </div>
                            <button className="action-button" onClick={() => markAsFinished(purchase.PurchaseID)}>
                                <FontAwesomeIcon icon={faCheck} />
                            </button>

                            <button className="action-button-cancel" onClick={() => markAsCanceled(purchase.PurchaseID)}>
                                <FontAwesomeIcon icon={faXmark} />
                            </button>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default TimelineAdmin;
