import { useEffect, useState, useTransition } from "react";
// import icona from "../photos/icona.PNG"
import icona from "../photos/ikona2.png"
import nav from "./NavBar.module.css"
import { Link } from "react-router-dom";
import 'flag-icon-css/css/flag-icons.min.css';
import { Cookies } from "../Functions/Cookies";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import productsOnDashboard from "../Data/ProductsOnDashboard";
import { useCookies } from "react-cookie";


export default function NavbarComponent({ message, prodNumber }) {

  console.log("shshshshhshshs " + prodNumber)
  const [productOnDash, setProductsOnDash] = useState()
  const [activeButton, setActiveButton] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t, i18n } = useTranslation('global'); // Specify the namespace if needed
  const [cookies, setCookies] = useCookies()
  console.log("Prodult " + cookies["products"]?.length)
  function handleButtonClick() {
    setActiveButton(!activeButton);
    message(activeButton);
  }

  function handleImageClick() {
    if (windowWidth < 960 && activeButton) {
      setActiveButton(false)
      message(activeButton)
    }
  }
  useEffect(() => {
    const handleResize = () => {
      const newWindowWidth = window.innerWidth;
      setWindowWidth(newWindowWidth);

      if (newWindowWidth > 960) {
        setActiveButton(false);
        message(true);

      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [activeButton]);


  const handleChangeLanguage = (lang) => {
    console.log("Languagae " + lang)
    i18next.changeLanguage(lang)



  }

  useEffect(() => {
    setProductsOnDash(productsOnDashboard.length)
  }, [productsOnDashboard])
  return (
    <div className={`${activeButton ? nav.navAbsolute : nav.navFixed} `}>

      <div className={`${nav.main}`} style={{ margin: "auto", marginTop: "20px", display: "flex", justifyContent: "center" }}>

        <div className={`d-flex container-fluid justify-content-center ${nav.main}`}>

          <div className={`${nav.left} ${nav.kos} `} style={{ marginTop: "25px" }}>
            {
              (cookies.role != "Administrator") ?
                <div className={`${nav.iconat}`} style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span className={`${nav.leftIcon} ${nav.leftIcona}`}><i className={`bi bi-telephone ${nav.icona} `}></i>  +383 44 187 767</span>
                  <span className={` ${nav.leftIcona}`}><i className={`bi bi-envelope ${nav.icona} `}></i> silverpointkosove@gmail.com</span>
                </div> :
                <div className={`${nav.iconat}`} style={{ display: "flex", justifyContent: "space-evenly" }}>
                  <span className={`${nav.leftIcon} ${nav.leftIcona}`}><Link to="/addNewProduct"><button className={`${nav.icona} ${nav.shtoniProdukt} `} style={{}}>Shtoni një produkt</button> </Link></span>
                  <span className={`${nav.leftIcon} ${nav.leftIcona}`}><Link to="/purchaseHistoryAdmin"><button className={`${nav.icona} ${nav.shtoniProdukt}`} style={{}}>Historia e blerjeve</button> </Link></span>

                  <span className={` ${nav.leftIcona}`}><i className={` ${nav.icona} `}></i></span>
                </div>
            }
            <hr className={`${nav.hr}`}></hr>
            <div className={`${nav.displayButtons}
                                ${activeButton ? `${nav.alb} ${nav.displayButtons} ${nav.displayLeft}` : `${nav.nodisp}`}
                                `} style={{ marginLeft: "10px", marginRight: "10%" }}>

              <Link to="productCategory/female"><button className={`${nav.butoni}`} onClick={handleImageClick}>{t("navBar.femra")}</button></Link>
              <Link to="productCategory/male"><button className={`${nav.butoni}`} onClick={handleImageClick}>{t("navBar.meshkuj")}</button></Link>
              <Link to="productCategory/unisex/Unaza"><button className={`${nav.butoni}` } onClick={handleImageClick}>{t("navBar.unaze")}</button></Link>  
             <Link to="productCategory/unisex/Komplete"><button className={`${nav.butoni}`} onClick={handleImageClick}>{t("navBar.komplete")}</button></Link> 
            </div>
          </div>
          <div className={`${nav.photo}`}>
            <Link to="/"><img className={`${nav.img}`} src={icona} style={{ opacity: "1",width:"180px"}} onClick={handleImageClick}></img></Link>
          </div>
          <div className={`${nav.right} ${nav.kos}`} style={{ marginTop: "25px" }}>

            <div className={`${nav.rightIcon}`} style={{ display: "flex", justifyContent: "flex-end" }}>
              {
                (cookies.role == "Administrator") ?
                  <div style={{ marginRight: "35%" }}> <span ><Link to="/allProductsAdmin"><button className={`${nav.icona} ${nav.shtoniProdukt}`} style={{}}>Produktet</button> </Link></span></div> : ""
              }

             {
               (cookies.role == "Administrator") ? "": <Link to="/purchaseHistoryUser"><span className={`${nav.leftIcon} ${nav.iconat}`}><i className={`bi bi-eye ${nav.icona}`}></i></span></Link>
             }

            
              <span className={`${nav.leftIcon} ${nav.iconat}`}><Link to={`${cookies.token == undefined ? "login" : "account"}`} className={`${nav.link}`}><i className={`bi bi-person ${nav.icona}`}></i></Link></span>
              {
                (cookies.role != "Administrator") ? <span className={`${nav.leftIcon}`}><Link to="dashboard" ><i className={`bi bi-cart-dash  ${nav.icona}`} onClick={handleImageClick}></i></Link></span> : ""
              }
              <Link to="dashboard" > <span style={{ position: "relative", right: "45px", bottom: "10px", fontSize: "18px", fontWeight: "bold", color: "gray", display: (cookies.role == "Administrator") ? "none" : "" }}>{prodNumber}</span></Link>
              <button className={`${nav.display} `} style={{ marginRight: "30px", position: "relative", top: "-8px", backgroundColor: "#771923", border: "none" }} onClick={handleButtonClick}><span style={{ color: "black", fontSize: "30px" }}><i className="bi bi-list" style={{ padding: "5px", marginTop: "10px", fontSize: "30px", color: "white" }}></i></span>
              </button>
            </div>
            <hr className={`${nav.hr}`}></hr>
            <div className={` ${nav.displayButtons}
                ${activeButton ? `${nav.alb} ${nav.displayButtons} ${nav.displayLeft}` : `${nav.nodisp}`}
                `} style={{ marginLeft: "10%", marginRight: "10px" }}>
              <Link to="productCategory/unisex/Byzylyke"><button className={`${nav.butoni}`} onClick={handleImageClick}>{t("navBar.byzylyk")}</button></Link> 
             <Link to="productCategory/unisex/Personalizime" className={`${nav.linku}`}> <button className={`${nav.butoni}`}  onClick={handleImageClick}>{t("navBar.personalizime")}</button></Link>
              <Link to="/store"><button className={`${nav.butoni}`}  onClick={handleImageClick}>{t("navBar.dyqane")}</button></Link>
             <Link className={`${nav.linku}`} style={{textDecoration:"none"}} to="/purchaseHistoryUser"> <button className={`${nav.butoni}`}  onClick={handleImageClick} id={`${nav.account}`} >{t("navBar.historia")}</button></Link> 
              <Link className={`${nav.linku}`} to="login" style={{textDecoration:"none"}} ><button className={`${nav.butoni}`} id={`${nav.account}`} onClick={handleButtonClick}>{t("navBar.llogaria")}</button></Link>

              <div className={`${nav.language}`} >
                <button className={`${nav.butoni}`}> <span className="flag-icon flag-icon-al" onClick={() => handleChangeLanguage("sq")}></span></button>
                <button className={`${nav.butoni}`}> <span className="flag-icon flag-icon-us" onClick={() => handleChangeLanguage("en")}></span></button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}