// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


.UserAccount_main__wxjyJ{
    margin-top: 250px !important;
    color: black;
    border: solid #c9c5c1 2px;
    width: 400px;
    max-width: 100%;
    margin: auto;
    background-color: #edeceb;


}

.UserAccount_divImage__qu\\+CS{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 50px;
}

.UserAccount_image__44N0N{
    padding: 1px;
    margin: auto;
    text-align: center;
    width: 50%;
}

.UserAccount_data__Lpt27{
    text-align: end;
}


.UserAccount_btn1__wP-xT {
    position: relative;
    width: 100%;
    height: 45px;
    background: transparent;
    border: 2px solid #771923;
    outline: none;
    border-radius: 40px;
    cursor: pointer;
    font-size: 16ox;
    color: #fff;
    font-weight: 600;
    z-index: 1;
    overflow: hidden;
}

.UserAccount_btn1__wP-xT::before {
    content: '';
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 300%;
    background: linear-gradient(#771923, #771923, #771923, #771923);
    z-index: -1;
    transition: .5s;
}

.UserAccount_btn1__wP-xT:hover::before {
    top: 0;
}


@media screen and (maxWidth:600px) {

    .UserAccount_main__wxjyJ{
        margin-top: 200px !important;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/pages/Account/UserAccount.module.css"],"names":[],"mappings":";;;AAGA;IACI,4BAA4B;IAC5B,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,yBAAyB;;;AAG7B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,eAAe;AACnB;;;AAGA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,UAAU;IACV,OAAO;IACP,WAAW;IACX,YAAY;IACZ,+DAA+D;IAC/D,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,MAAM;AACV;;;AAGA;;IAEI;QACI,4BAA4B;IAChC;;AAEJ","sourcesContent":["\n\n\n.main{\n    margin-top: 250px !important;\n    color: black;\n    border: solid #c9c5c1 2px;\n    width: 400px;\n    max-width: 100%;\n    margin: auto;\n    background-color: #edeceb;\n\n\n}\n\n.divImage{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 30px;\n    margin-bottom: 50px;\n}\n\n.image{\n    padding: 1px;\n    margin: auto;\n    text-align: center;\n    width: 50%;\n}\n\n.data{\n    text-align: end;\n}\n\n\n.btn1 {\n    position: relative;\n    width: 100%;\n    height: 45px;\n    background: transparent;\n    border: 2px solid #771923;\n    outline: none;\n    border-radius: 40px;\n    cursor: pointer;\n    font-size: 16ox;\n    color: #fff;\n    font-weight: 600;\n    z-index: 1;\n    overflow: hidden;\n}\n\n.btn1::before {\n    content: '';\n    position: absolute;\n    top: -100%;\n    left: 0;\n    width: 100%;\n    height: 300%;\n    background: linear-gradient(#771923, #771923, #771923, #771923);\n    z-index: -1;\n    transition: .5s;\n}\n\n.btn1:hover::before {\n    top: 0;\n}\n\n\n@media screen and (maxWidth:600px) {\n\n    .main{\n        margin-top: 200px !important;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `UserAccount_main__wxjyJ`,
	"divImage": `UserAccount_divImage__qu+CS`,
	"image": `UserAccount_image__44N0N`,
	"data": `UserAccount_data__Lpt27`,
	"btn1": `UserAccount_btn1__wP-xT`
};
export default ___CSS_LOADER_EXPORT___;
