// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.Card_containeri__zFvV2{
    /* width: 75%;
    margin: auto;
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-between;
    z-index: -1; */
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: auto;
    z-index: -1 !important;
    width: 70% !important;

}


.Card_cardBody__Baq7-{
    margin: 0 !important;
    padding: 0 !important;
    height: 10% !important;
    display: none;
    }

.Card_spani__9M7V1{
    width: 100%;
    height: 100%;
    display: block;
    background-color: #edeceb;
    text-align: end;
    padding: 10px !important;
    color: red;
    font-weight: 600;

}
.Card_karta__FMWVG:hover{

    .Card_cardBody__Baq7-{
        display: block;
    }

    .Card_cardHeader__mxIL\\+{
        height: 77% !important;
    }
}


@media screen and (max-width:1750px) {
    
    .Card_containeri__zFvV2{
        width: 90% !important;
    }


}
@media screen and (max-width:916px) {
    
 
    


}`, "",{"version":3,"sources":["webpack://./src/components/Card/Card.module.css"],"names":[],"mappings":";;AAEA;IACI;;;;;kBAKc;IACd,aAAa;IACb,2BAA2B;IAC3B,eAAe;IACf,YAAY;IACZ,sBAAsB;IACtB,qBAAqB;;AAEzB;;;AAGA;IACI,oBAAoB;IACpB,qBAAqB;IACrB,sBAAsB;IACtB,aAAa;IACb;;AAEJ;IACI,WAAW;IACX,YAAY;IACZ,cAAc;IACd,yBAAyB;IACzB,eAAe;IACf,wBAAwB;IACxB,UAAU;IACV,gBAAgB;;AAEpB;AACA;;IAEI;QACI,cAAc;IAClB;;IAEA;QACI,sBAAsB;IAC1B;AACJ;;;AAGA;;IAEI;QACI,qBAAqB;IACzB;;;AAGJ;AACA;;;;;;AAMA","sourcesContent":["\n\n.containeri{\n    /* width: 75%;\n    margin: auto;\n    display: flex !important;\n    flex-wrap: wrap !important;\n    justify-content: space-between;\n    z-index: -1; */\n    display: flex;\n    justify-content: flex-start;\n    flex-wrap: wrap;\n    margin: auto;\n    z-index: -1 !important;\n    width: 70% !important;\n\n}\n\n\n.cardBody{\n    margin: 0 !important;\n    padding: 0 !important;\n    height: 10% !important;\n    display: none;\n    }\n\n.spani{\n    width: 100%;\n    height: 100%;\n    display: block;\n    background-color: #edeceb;\n    text-align: end;\n    padding: 10px !important;\n    color: red;\n    font-weight: 600;\n\n}\n.karta:hover{\n\n    .cardBody{\n        display: block;\n    }\n\n    .cardHeader{\n        height: 77% !important;\n    }\n}\n\n\n@media screen and (max-width:1750px) {\n    \n    .containeri{\n        width: 90% !important;\n    }\n\n\n}\n@media screen and (max-width:916px) {\n    \n \n    \n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containeri": `Card_containeri__zFvV2`,
	"cardBody": `Card_cardBody__Baq7-`,
	"spani": `Card_spani__9M7V1`,
	"karta": `Card_karta__FMWVG`,
	"cardHeader": `Card_cardHeader__mxIL+`
};
export default ___CSS_LOADER_EXPORT___;
