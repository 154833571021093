// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pagination_item__FRQCI {
    margin: 5px;
    width: 60px;
    cursor: pointer;
}

.Pagination_item__FRQCI span:hover{
    color: #771923;
}
.Pagination_item__FRQCI span {
    border-radius: 50%;
    text-align: center;
    background-color: #edeceb;
    color: #771923;

}

.Pagination_main__DKdj-{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: auto;
    z-index: -1 !important;
    width: 70%;
}`, "",{"version":3,"sources":["webpack://./src/components/Paggination/Pagination.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;;AAElB;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,eAAe;IACf,YAAY;IACZ,sBAAsB;IACtB,UAAU;AACd","sourcesContent":[".item {\n    margin: 5px;\n    width: 60px;\n    cursor: pointer;\n}\n\n.item span:hover{\n    color: #771923;\n}\n.item span {\n    border-radius: 50%;\n    text-align: center;\n    background-color: #edeceb;\n    color: #771923;\n\n}\n\n.main{\n    display: flex;\n    justify-content: flex-start;\n    flex-wrap: wrap;\n    margin: auto;\n    z-index: -1 !important;\n    width: 70%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `Pagination_item__FRQCI`,
	"main": `Pagination_main__DKdj-`
};
export default ___CSS_LOADER_EXPORT___;
