import axios from "axios";
import produkte from "../../ban.js";


export function prod(page,limits,products){

    // console.log("Produkte "+ products.length)
    let array = []
    let prod = limits*page
    for(let i=limits*page-limits; i<prod ; i++){

        if( products[i] != undefined)
        array.push(products[i])
        
         
        // array.splice(array.length-nullProd)
        

    }
    return array;
}


export function getLength(products){

    return products.length
}