
import _ from "lodash"

export function util(totalPage, page, limits){

    let totalPageNumber = totalPage<7
    
    if(totalPageNumber){
        return _.range(1,totalPage+1)
    }
}