import productsOnDashboard from "../Data/ProductsOnDashboard";


export function IsProductOnDashboard(productId){


    for(let i=0; i< productsOnDashboard.length; i++){

        if(productsOnDashboard[i].id == productId){
            return true;
        }
    }
    return false;
}