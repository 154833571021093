import style from "./Footer.module.css"
// import icona from "../../photos/icona.PNG"
import image from "../../photos/footerIcon.png"
import icona from "../../photos/fotIc.png"

export default function Footer() {


    return (
        <div style={{ backgroundColor: "#771923", marginTop: "80px", marginBottom: "0" }}>
            <div className={`${style.row}`} style={{ height: "250px" }}>
                <div className={` ${style.icons}`}>
                    <span className={`${style.specifcIcon}`}><a href="https://www.facebook.com/share/9YiofYGqvosZFW91/" target="_blank"><i class="bi bi-facebook" style={{color:"white"}} ></i></a></span>
                    <span className={`${style.specifcIcon}`}><a href="https://www.instagram.com/silverpointofficial?igsh=MTQ1MTI4NHJpcXB6cQ==" target="_blank"><i className={`${style.specifcIcon}`} class="bi bi-instagram" style={{color:"white"}}></i></a></span>
                    <span className={`${style.specifcIcon}`}><a href="https://www.youtube.com/channel/UCnb5ntbGcqhBjIBC2CtS0cA?view_as=subscriber" target="_blank"><i className={`${style.specifcIcon}`} class="bi bi-youtube" style={{color:"white"}}></i></a></span>
                    <span className={`${style.specifcIcon}`}><a href="https://www.tiktok.com/@silverpointi?_t=8nhHfOE0nVW&_r=1" target="_blank"><i className={`${style.specifcIcon}`} class="bi bi-tiktok" style={{color:"white"}}></i></a></span>

                    {/* <span className={`${style.specifcIcon}`}> <i className={``} class="bi bi-twitter" style={{}}></i></span> */}
                </div>
                <div className={` ${style.image}`}>
                    <img src={icona} style={{ opacity: "0.7", width:"180px" }}></img>
                    <div style={{marginTop:"10px"}}>
                        <span className={``} ><i className={`bi bi-telephone  `} ></i> +383 44 187 767</span>
                        <span className={` `} style={{ whiteSpace: "nowrap" }}><i className={`bi bi-envelope  `}></i>silverpointkosove@gmail.com</span>
                    </div>
                </div>
                <div className={` ${style.subscribe}`}>
                    <div className="" style={{ width: "fit-content" }}>
                        <p style={{ textAlign: "start", fontSize: "22px" }}>Abonohu per me te rejat</p>
                        <div class={`input-group input-group ${style.group}`} style={{ whiteSpace: "nowrap" }} >
                            <input className={`${style.inputFild}`} type="text" class="" placeholder="Emaili juaj " style={{ backgroundColor: "transparent", color: "white" }} />
                            <button class="btn" style={{ backgroundColor: "white" }}>Get started</button>
                        </div>
                    </div>


                </div>
            </div>
            <div className={`${style.hline}`}></div>
            <div style={{ color: "white", textAlign: "end", width: "90%", margin: "auto", marginTop: "8px", fontSize: "18px", }}><a href="https://www.facebook.com/profile.php?id=61561486274957" target="_blank" style={{color:"white", textDecoration:"none"}}><p>Web support by AnSy</p></a></div>
        </div>
    )
}