


const specificProduct = [
    {
        "id": "1",
        "img": "https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price": "111",
        "discount": "70%",
        "name": "Unaze"
    },
    {
        "id": "2",
        "img": "https://dam.bluenile.com/images/public/16588/Lab_Grown_Diamonds.jpeg",
        "price": 123,
        "discount": "30%",
        "name": "Zingjire"
    },



    {
        "id": "3",
        "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
        "price": 234,
        "discount": "40%",
        "name": "Ore"
    },
    {
        "id":"4",
        "img": "https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price": "111",
        "discount": "70%",
        "name": "Unaze"
    },
    {        "id":"5",

        "img": "https://www.bobswatches.com/rolex-blog/wp-content/uploads/2022/11/Omega-Dress-Watches.jpg",
        "price": 234,
        "discount": "40%",
        "name": "Ore"
    },
    {        "id":"6",

        "img": "https://www.wallacebishop.com.au/cdn/shop/collections/yellow-gold-engagement-rings-wallace-bishop_758ed61f-2995-4ab5-a88a-8b93701a375a.jpg?v=1689664452",
        "price": "111",
        "discount": "70%",
        "name": "Unaze"
    },
    {        
        "id":"7",
        "img": "https://dam.bluenile.com/images/public/16588/Lab_Grown_Diamonds.jpeg",
        "price": 123,
        "discount": "30%",
        "name": "Zingjire"
    },
]


export default specificProduct;